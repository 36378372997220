.primary_bg.sort_button {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.sort_button:focus {
  box-shadow: none !important;
}

.btn-check:focus + .btn,
.btn:focus {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: none !important;
}

.scannerId,
.updatedAt,
.createdAt,
.jobUploadStatus,
.comment,
.variantId,
.productId,
.jobName,
.clientId {
  background: #0050aa !important;
  color: #fff;
  border-radius: 5px;
}

.scanJob_table {
  position: relative;
  table-layout: auto;
  text-align: center;
}

.scanJob_filter_loader {
  position: absolute;
  background: #353b42d4;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  top: 0;
  color: #fff;
}

.scanJob_table.table-bordered tr td {
  font-size: 12px;
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-bottom: 12px !important;
  text-align: center;
}

td {
  word-wrap: normal;
}

.single_line {
  white-space: nowrap;
}

.scanJob_table.table-bordered tr th {
  font-size: 11px;
}

.scanjob_btn {
  padding: 5px 10px;
  border-radius: 4px;
  background: #333;
  color: #fff;
}

.scanjob_btn i {
  font-size: 12px;
}

.scanjob_btn:hover {
  background: black !important;
  color: #fff;
}

.scanjob_size {
  font-size: 12px;
  font-weight: bold;
  margin-top: 10%
}

.page-item.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.rmv_margin {
  margin-left: 10px !important;
  margin-right: 10px !important;
}