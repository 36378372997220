.warning_area {
  background: red;
  color: #fff;
  font-weight: 700;
  width: 25px;
  height: 25px;
  display: inline-block;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 16px;
}

.d-flex.modal-body {
  align-items: center;
}

/* IAN confirm Modal */

.ian_modal_title {
  color: #353B42;
  font-size: 27px;
  font-weight: 800;
}

.ian_modal.modal-header {
  border: none;
  padding: 24px;
  padding-bottom: 0px;
  padding-top: 18px;
}

.ian_modal_body .modal-content {
  width: 700px !important;
}

.modal_intro {
  border: 1px solid #0050AA;
  border-radius: 16px;
  padding: 24px;
  width: 630px;
}

.modal_intro h4 {
  font-size: 17px;
  font-weight: 500;
  color: #0050AA;
}

.modal_intro p {
  font-size: 14px;
  color: #6B7280;
  line-height: 20px;
  margin-bottom: 0;
}

.ian_modal_body .modal-dialog {
  max-width: 680px !important;
}

.ian_modal_body .modal-body {
  padding: 24px;
  padding-top: 19px;
}

tr.table_item td {
  padding-bottom: 3px !important;
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.table_style_title {
  width: 150px;
}

.form_info {
  margin-top: 30px;
}

.table_item {
  font-size: 16px;
  color: #353B42;
}

.single_style h5 {
  font-size: 18px;
  color: #353B42;
  margin-bottom: 20px;
}

.single_style {
  border: 1px solid #F3F4F6;
  padding: 10px;
  margin-bottom: 16px;
}

.style_front {
  text-align: center;
  margin-top: -8px;
}

.ian_submit_btn {
  margin-top: 27px;
}

/* Success Modal */
.success_message h4 {
  color: #353B42;
  font-size: 18px;
  font-weight: 500;
  margin-top: 22px;
}

.success_message p {
  color: #6B7280;
  font-size: 12px;
  line-height: 20px;
}

.success_message {
  padding: 24px;
  padding-top: 10px;
  padding-bottom: 12px;
}

.close_success_modal {
  width: 100%;
  margin-bottom: 0px;
  background: #0050AA;
  color: #fff;
  display: flex;
  height: 38px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.fileNameSlot {
  display: flex;
  flex-direction: column;
}

.fittedBedsheetURLView {
  margin-top: 10px;
}



.style_back_Baby_Bedlinen {
  margin-left: -53px;
  margin-top: -45px;
}

.style_front_Baby_Bedlinen {
  margin-top: -25px;
}

.submit_area {
  position: absolute;
  background: #353b42d9;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.submit_area p {
  color: #fff;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
}

.notofy_label {
  display: flex;
  align-items: center;
  color: #000;
  font-weight: 500;
}

.label_notify_email {
  font-size: 11px;
}