.menubar_area {
  background: #ffffff;
  padding: 5px 0;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
}

.menu_div {
  width: 100%;
}

.profile_avatar {
  width: 25px;
  border-radius: 50%;
  height: 25px;
  background: #ffffff;
  padding: 1px;
  margin-right: 10px;
}

.fa.fa-sign-out {
  color: #353b42;
}